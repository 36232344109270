var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "bottom-info"
  }, [_c("div", [_c("span", [_vm._v("Powered by")]), _vm._m(0), _c("el-divider", {
    attrs: {
      direction: "vertical"
    }
  }), _c("span", [_vm._v("Copyright")]), _c("span", [_vm._v("ZLDNS")]), _c("el-divider", {
    attrs: {
      direction: "vertical"
    }
  }), _c("span", [_vm._v(_vm._s(_vm.version))])], 1)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("span", [_c("a", {
    attrs: {
      href: "https://www.zenlayer.com/"
    }
  }, [_vm._v("Zenlayer")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };